<template>
  <div class="">
    <FirstPage
      @SecondPage="scrollElemet('SecondPage')"
      @ThirdPage="scrollElemet('ThirdPage')"
      @FourPage="scrollElemet('FourPage')"
      id="FirstPage"
    />

    <SecondPage id="SecondPage" />

    <ThirdPage id="ThirdPage" />

    <FourPage id="FourPage" />

    <Footer />
  </div>
</template>

<style scoped>
</style>


<script>
import FirstPage from "./components/FirstPage";
import SecondPage from "./components/SecondPage";
import ThirdPage from "./components/ThirdPage";
import FourPage from "./components/FourPage";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    FirstPage,
    SecondPage,
    ThirdPage,
    FourPage,
    Footer,
  },
  methods: {
    scrollElemet(id) {
      document.getElementById(id).scrollIntoView();
    },
  },
};
</script>
