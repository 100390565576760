import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import './assets/css/style.css'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import MaskedInput from 'vue-masked-input'
import VmBackTop from 'vue-multiple-back-top'





Vue.config.productionTip = false

new Vue({
  BootstrapVue,
  MaskedInput,
  VmBackTop,
  render: h => h(App)
}).$mount('#app')

