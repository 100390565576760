<template>
  <div class="oveflow-hidden">
    <div class="d-sm-block d-mb-block d-lg-none">
      <div class="section-5-tablet">
        <div class="five-page-container">
          <el-row :gutter="10" class="">
            <el-col :span="24" class="footer-padding-tablet">
              <div class="" style="text-align: center">
                <img class="dragLogo-tablet" src="../assets/dragLogo.svg" />
              </div>
            </el-col>
            <el-col :span="24" class="footer-padding-tablet-2">
              <div class="center">
                <i class="el-icon-location-outline"></i>
                <p class="footer-text">
                  Республика Казахстан, 050010 Алматы, ул. Бегалина 42
                </p>
              </div>
            </el-col>
            <el-col :span="24" class="footer-padding-tablet-2">
              <div class="center" style="display: flex; flex-direction: column">
                <i class="el-icon-phone-outline"
                  ><a class="mailto-text" href="tel:+77272411728"></a
                ></i>
                <a class="mailto-text" href="tel:+77272411728"
                  >+7 (727) 241 17 28</a
                >
              </div>
            </el-col>
            <el-col :span="24" class="footer-padding-tablet-3">
              <div class="center" style="display: flex; flex-direction: column">
                <i class="el-icon-message"
                  ><a class="mailto-text" href="mailto:info@dragau.kz"></a
                ></i>
                <a class="mailto-text" href="mailto:info@dragau.kz"
                  >Напишите нам</a
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="section-5">
        <div class="five-page-container">
          <el-row :gutter="10" class="server-margin">
            <el-col :span="6">
              <div class="">
                <img class="dragLogo" src="../assets/dragLogo.svg" />
              </div>
            </el-col>
            <el-col :span="6">
              <div class="center">
                <i class="el-icon-location-outline"></i>
                <p class="footer-text">
                  Республика Казахстан, 050010 Алматы, ул. Бегалина 42
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="center" style="display: flex; flex-direction: column">
                <i class="el-icon-phone-outline"
                  ><a class="mailto-text" href="tel:+77272411728"></a
                ></i>
                <a class="mailto-text" href="tel:+77272411728"
                  >+7 (727) 241 17 28</a
                >
              </div>
            </el-col>
            <el-col :span="6">
              <div class="center" style="display: flex; flex-direction: column">
                <i class="el-icon-message"
                  ><a class="mailto-text" href="mailto:info@dragau.kz"></a
                ></i>
                <a class="mailto-text" href="mailto:info@dragau.kz"
                  >Напишите нам</a
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.mailto-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
}
.oveflow-hidden {
  overflow-x: hidden;
}
.footer-padding-tablet {
  margin-bottom: 7.467vh;
}

.footer-padding-tablet-2 {
  margin-bottom: 5.911vh;
}

.footer-padding-tablet-3 {
  margin-bottom: 4.926vh;
}

.draglogo-tablet {
  width: 58.933vw;
  height: 4.233vh;
}
.section-5-tablet {
  background-color: black;
  width: 100vw;
  height: 100%;
  padding-top: 3.079vh;
}
.center {
  text-align: center;
}
i {
  color: white;
  font-size: 2em;
}

.footer-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
}
.five-page-container {
  margin-left: 10.764vw;
  margin-right: 10.764vw;
  padding-top: 4.653vh;
}
.section-5 {
  background-color: black;
  width: 100vw;
  height: 22.778vh;
}
</style>

<script>
export default {
  name: "Footer",
};
</script>