<template>
  <div class="oveflow-hidden">
    <div class="d-sm-block d-mb-block d-lg-none">
      <div class="section-3">
        <div class="section-first-main-tablet">
          <div class="main-content">
            <div class="section-3-container-tablet">
              <div class="center">
                <el-divider direction="vertical"></el-divider>
              </div>
              <h1 class="main-title-tablet">Создание и развитие продукта</h1>
              <el-row>
                <el-col :span="24">
                  <div class="" style="text-align: center">
                    <img
                      src="../assets/sharingan-mobile.svg"
                      class="sharingan-img-tablet"
                      alt=""
                    />
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="">
                    <p class="description-red-tablet">
                      Компания «DragAu» предоставляет услуги по разработке
                      корпоративных систем любой сложности. Проекты могут
                      предусматривать как разработку нового ПО «с нуля»,так и
                      доработку существующего ПО в связи изменением требований к
                      нему.
                    </p>
                    <p class="description-white-tablet">Оказываемые услуги:</p>
                    <ul class="description-benefits-tablet pl-3">
                      <li>Разработка ПО по требованиям заказчика</li>
                      <li>Разработка корпоративных систем</li>
                      <li>Разработка мобильных приложений</li>
                      <li>Разработка веб-приложений</li>
                      <li>Интеграция ПО с существующими системами</li>
                      <li>Доработка и развитие разработанного ПО</li>
                      <li>Расширение функциональности ПО</li>
                    </ul>
                  </div>
                </el-col>
              </el-row>
              <div class="mb-3">
                <p class="small-description-tablet">
                  Принципы управления проектами в компании основаны на
                  методологии PRINCE2, являющейся одной из ведущих методологией
                  проектного управления в сфере ИТ. Все специалисты по
                  управлению проектами компании имеют сертификат PRINCE2 и опыт
                  управления проектами по данной методологии.
                </p>
                <p class="small-description-tablet">
                  Процесс управления проектами включает использование
                  специализированного ПО, позволяющего эффективно управлять как
                  отдельным проектом, так и портфелем проектов.
                </p>
              </div>
              <el-collapse>
                <el-collapse-item>
                  <template class="collapse-flex" slot="title">
                    <div class="">
                      <p class="details-tablet">
                        подробнее <i class="el-icon-bottom"></i>
                      </p>
                    </div>
                  </template>
                  <div class="details-content">
                    <p class="details-title-tablet">
                      Основными стадиями проекта разработки ПО являются:
                    </p>

                    <el-row :gutter="20" class="server-margin">
                      <el-col :xs="24" :sm="12">
                        <div class="">
                          <p class="red-number-tablet mb-2">1</p>
                          <p class="project-title-tablet">
                            Проведение диагностики и анализа
                          </p>
                          <p class="project-text-tablet">
                            Специалисты анализируют требования со стороны
                            Заказчика, изучают сферу бизнеса, делают выбор
                            решений, изучают конкурентов, целевую аудиторию и
                            т.д. Аналитика позволяет определить требования к
                            качеству итогового продукта
                          </p>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <div class="">
                          <p class="red-number-tablet mb-2">2</p>
                          <p class="project-title-tablet">
                            Концептуальное проектирование решения
                          </p>
                          <p class="project-text-tablet">
                            Прототипы позволяют понять,как будет выглядеть
                            приложение.После утверждения составляется ТЗ и
                            специалисты переходят к разработке дизайна и самого
                            приложения.
                          </p>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="server-margin">
                      <el-col :xs="24" :sm="12">
                        <div class="">
                          <p class="red-number-tablet mb-2">3</p>
                          <p class="project-title-tablet">Разработка дизайна</p>
                          <p class="project-text-tablet">
                            Специалист создает дизайн приложения, опираясь на
                            собственные платформы, фирменный стиль компании,
                            нишу бизнеса и множество других факторов. Особое
                            внимание уделяется удобству использования
                            интерфейса.
                          </p>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <div class="">
                          <p class="red-number-tablet mb-2">4</p>
                          <p class="project-title-tablet">
                            Настройка и разработка
                          </p>
                          <p class="project-text-tablet">
                            Включает программирование приложения. Специалисты
                            создают модули, индивидуальные инструменты,
                            используют подходящий стек технологий. Это самая
                            продолжительная часть работы, включающая усилия всех
                            членов команды.
                          </p>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="server-margin">
                      <el-col :xs="24" :sm="12">
                        <div class="">
                          <p class="red-number-tablet mb-2">5</p>
                          <p class="project-title-tablet">
                            Интеграция с внешними системами
                          </p>
                          <p class="project-text-tablet">
                            Разработчики, при необходимости, подключат сторонние
                            сервисы, внешние системы или инструменты,
                            необходимые в соответствии с утвержденным ТЗ. На
                            данном этапе часто требуется участие разработчиков
                            интегрируемых систем.
                          </p>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <div class="">
                          <p class="red-number-tablet mb-2">6</p>
                          <p class="project-title-tablet">Тестирование</p>
                          <p class="project-text-tablet">
                            Проводится несколько этапов тестирования, ищутся
                            неточности в коде, имитируется деятельность
                            потенциального пользователя, определяется качество
                            интерфейса. Если есть недостатки, вносятся
                            коррективы и они устраняются.
                          </p>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row :gutter="20" class="server-margin">
                      <el-col :xs="24" :sm="12">
                        <div class="">
                          <p class="red-number-tablet mb-2">7</p>
                          <p class="project-title-tablet">
                            Обучение пользователей и подготовка к эксплуатации
                          </p>
                          <p class="project-text-tablet">
                            Когда приложение прошло тестирование мы обучаем
                            сотрудников Заказчика пользоваться интерфейсом и
                            работать с контентом. В рамках этапа готовятся
                            пользовательские инструкции.
                          </p>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="12">
                        <div class="">
                          <p class="red-number-tablet mb-2">8</p>
                          <p class="project-title-tablet">
                            Передача системы в эксплуатацию и сопровождение
                          </p>
                          <p class="project-text-tablet">
                            После старта эксплуатации производится гарантийная
                            техническая поддержка внедрённого функционала. При
                            необходимости, могут оказываться услуги по развитию
                            функционала приложения.
                          </p>
                        </div>
                      </el-col>
                    </el-row>

                    <!-- <div class="">
                                    <p class="details">свернуть <i class="el-icon-top"></i></p>
                                </div> -->
                  </div>
                </el-collapse-item>
              </el-collapse>

              <el-divider class=""></el-divider>

              <div class="contact-us">
                <p class="contact-text-tablet">Если вам это интересно</p>
                <!-- <el-button class="btn">Напишите нам</el-button> -->
                <button class="btn-tablet">
                  <a
                    class="mailto-text"
                    href="mailto:info@dragau.kz?subject=Создание и развитие продукта!"
                    >Напишите нам</a
                  >
                </button>
                <!-- <el-button
                  class="btn-tablet"
                  @click="centerDialogVisible = true"
                  >Напишите нам</el-button
                > -->
                <el-dialog
                  title="Напишите нам"
                  :visible.sync="centerDialogVisible"
                  width="93.600vw"
                  center
                >
                  <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                    <el-form-item class="dFlex" label="Кому:">
                      <p class="input-text-tablet">info@dragau.kz</p>
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item class="dFlex" label="Имя:" prop="name">
                      <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item class="dFlex" label="От:" prop="email">
                      <el-input
                        placeholder="Введите свой email"
                        v-model="ruleForm.email"
                      ></el-input>
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item class="dFlex" label="Телефон:" prop="phone">
                      <masked-input
                        v-model="ruleForm.phone"
                        mask="\+\7 (111) 111-11-11"
                        placeholder="+7 ("
                      />
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item
                      class="dFlex-column"
                      label="Тема сообщения:"
                      prop="variants"
                    >
                      <p class="input-text-tablet">
                        Создание и развитие продукта
                      </p>
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item label="Сообщение:" prop="desc">
                      <el-input
                        type="textarea"
                        style="margin-top: 10px"
                        v-model="ruleForm.desc"
                      ></el-input>
                    </el-form-item>
                    <!-- <el-form-item>
                                <el-button type="primary" @click="onSubmit">Create</el-button>
                            </el-form-item> -->
                  </el-form>
                  <span slot="footer" class="dialog-footer">
                    <!-- <el-button @click="centerDialogVisible = false">Cancel</el-button> -->
                    <el-button class="btn-red" @click="submitForm('ruleForm')"
                      >Отправить</el-button
                    >
                  </span>
                </el-dialog>
              </div>
              <div class="center">
                <el-divider direction="vertical"></el-divider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="section-3">
        <div class="section-third-main">
          <div class="main-content">
            <div class="third-page-container">
              <div class="center">
                <el-divider direction="vertical"></el-divider>
              </div>
              <h1 class="main-title">Создание и развитие продукта</h1>
              <el-row>
                <el-col :span="9">
                  <div class="">
                    <img
                      src="../assets/sharingan.svg"
                      class="sharingan-img"
                      alt=""
                    />
                  </div>
                </el-col>
                <el-col :span="15">
                  <div class="">
                    <p class="description-red">
                      Компания «DragAu» предоставляет услуги по разработке
                      корпоративных систем любой сложности. Проекты могут
                      предусматривать как разработку нового ПО «с нуля»,так и
                      доработку существующего ПО в связи изменением требований к
                      нему.
                    </p>
                    <p class="description-white">Оказываемые услуги:</p>
                    <ul class="description-benefits">
                      <li>Разработка ПО по требованиям заказчика</li>
                      <li>Разработка корпоративных систем</li>
                      <li>Разработка мобильных приложений</li>
                      <li>Разработка веб-приложений</li>
                      <li>Интеграция ПО с существующими системами</li>
                      <li>Доработка и развитие разработанного ПО</li>
                      <li>Расширение функциональности ПО</li>
                    </ul>
                  </div>
                </el-col>
              </el-row>
              <div class="small-description-container">
                <p class="small-description">
                  Принципы управления проектами в компании основаны на
                  методологии PRINCE2, являющейся одной из ведущих методологией
                  проектного управления в сфере ИТ. Все специалисты по
                  управлению проектами компании имеют сертификат PRINCE2 и опыт
                  управления проектами по данной методологии.
                </p>
                <p class="small-description-2">
                  Процесс управления проектами включает использование
                  специализированного ПО, позволяющего эффективно управлять как
                  отдельным проектом, так и портфелем проектов.
                </p>
              </div>
              <el-collapse>
                <el-collapse-item>
                  <template class="collapse-flex" slot="title">
                    <div class="">
                      <p class="details">
                        подробнее <i class="el-icon-bottom"></i>
                      </p>
                    </div>
                  </template>
                  <div class="details-content">
                    <p class="details-title">
                      Основными стадиями проекта разработки ПО являются:
                    </p>

                    <el-row :gutter="10" class="server-margin">
                      <el-col :span="8">
                        <div class="">
                          <p class="red-number">1</p>
                          <p class="project-title">
                            Проведение диагностики и анализа
                          </p>
                          <p class="project-text">
                            Специалисты анализируют требования со стороны
                            Заказчика, изучают сферу бизнеса, делают выбор
                            решений, изучают конкурентов, целевую аудиторию и
                            т.д. Аналитика позволяет определить требования к
                            качеству итогового продукта
                          </p>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="">
                          <p class="red-number">2</p>
                          <p class="project-title">
                            Концептуальное проектирование решения
                          </p>
                          <p class="project-text">
                            Прототипы позволяют понять,как будет выглядеть
                            приложение.После утверждения составляется ТЗ и
                            специалисты переходят к разработке дизайна и самого
                            приложения.
                          </p>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="">
                          <p class="red-number">3</p>
                          <p class="project-title">Разработка дизайна</p>
                          <p class="project-text">
                            Специалист создает дизайн приложения, опираясь на
                            собственные платформы, фирменный стиль компании,
                            нишу бизнеса и множество других факторов. Особое
                            внимание уделяется удобству использования
                            интерфейса.
                          </p>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row :gutter="10" class="server-margin">
                      <el-col :span="8">
                        <div class="">
                          <p class="red-number">4</p>
                          <p class="project-title">Настройка и разработка</p>
                          <p class="project-text">
                            Включает программирование приложения. Специалисты
                            создают модули, индивидуальные инструменты,
                            используют подходящий стек технологий. Это самая
                            продолжительная часть работы, включающая усилия всех
                            членов команды.
                          </p>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="">
                          <p class="red-number">5</p>
                          <p class="project-title">
                            Интеграция с внешними системами
                          </p>
                          <p class="project-text">
                            Разработчики, при необходимости, подключат сторонние
                            сервисы, внешние системы или инструменты,
                            необходимые в соответствии с утвержденным ТЗ. На
                            данном этапе часто требуется участие разработчиков
                            интегрируемых систем.
                          </p>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="">
                          <p class="red-number">6</p>
                          <p class="project-title">Тестирование</p>
                          <p class="project-text">
                            Проводится несколько этапов тестирования, ищутся
                            неточности в коде, имитируется деятельность
                            потенциального пользователя, определяется качество
                            интерфейса. Если есть недостатки, вносятся
                            коррективы и они устраняются.
                          </p>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row :gutter="10" class="server-margin">
                      <el-col :span="8">
                        <div class="">
                          <p class="red-number">7</p>
                          <p class="project-title">
                            Обучение пользователей и подготовка к эксплуатации
                          </p>
                          <p class="project-text">
                            Когда приложение прошло тестирование мы обучаем
                            сотрудников Заказчика пользоваться интерфейсом и
                            работать с контентом. В рамках этапа готовятся
                            пользовательские инструкции.
                          </p>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="">
                          <p class="red-number">8</p>
                          <p class="project-title">
                            Передача системы в эксплуатацию и сопровождение
                          </p>
                          <p class="project-text">
                            После старта эксплуатации производится гарантийная
                            техническая поддержка внедрённого функционала. При
                            необходимости, могут оказываться услуги по развитию
                            функционала приложения.
                          </p>
                        </div>
                      </el-col>
                    </el-row>

                    <!-- <div class="">
                                    <p class="details">свернуть <i class="el-icon-top"></i></p>
                                </div> -->
                  </div>
                </el-collapse-item>
              </el-collapse>

              <el-divider class=""></el-divider>

              <div class="contact-us">
                <p class="contact-text">Если вам это интересно</p>
                <button class="btn">
                  <a
                    class="mailto-text"
                    href="mailto:info@dragau.kz?subject=Создание и развитие продукта!"
                    >Напишите нам</a
                  >
                </button>
                <!-- <el-button class="btn" @click="centerDialogVisible = true"
                  >Напишите нам</el-button
                > -->
                <el-dialog
                  title="Напишите нам"
                  :visible.sync="centerDialogVisible"
                  width="52.431vw"
                  center
                >
                  <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                    <el-form-item class="dFlex" label="Кому:">
                      <p class="input-text">info@dragau.kz</p>
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item class="dFlex" label="Имя:" prop="name">
                      <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item class="dFlex" label="От:" prop="email">
                      <el-input
                        placeholder="Введите свой email"
                        v-model="ruleForm.email"
                      ></el-input>
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item class="dFlex" label="Телефон:" prop="phone">
                      <masked-input
                        v-model="ruleForm.phone"
                        mask="\+\7 (111) 111-11-11"
                        placeholder="+7 ("
                      />
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item
                      class="dFlex"
                      label="Тема сообщения:"
                      prop="variants"
                    >
                      <p class="input-text">Создание и развитие продукта</p>
                    </el-form-item>
                    <el-divider class="dividerForm"></el-divider>
                    <el-form-item label="Сообщение:" prop="desc">
                      <el-input
                        type="textarea"
                        style="margin-top: 10px"
                        v-model="ruleForm.desc"
                      ></el-input>
                    </el-form-item>
                    <!-- <el-form-item>
                                <el-button type="primary" @click="onSubmit">Create</el-button>
                            </el-form-item> -->
                  </el-form>
                  <span slot="footer" class="dialog-footer">
                    <!-- <el-button @click="centerDialogVisible = false">Cancel</el-button> -->
                    <el-button class="btn-red" @click="submitForm('ruleForm')"
                      >Отправить</el-button
                    >
                  </span>
                </el-dialog>
              </div>
              <div class="center">
                <el-divider direction="vertical"></el-divider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-tablet :hover {
  background-color: black !important;
  color: white;
}

.btn :hover {
  background-color: #eb2629 !important;
  color: white;
}
.mailto-text {
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  color: white;
  text-decoration: none;
}
::v-deep .el-dialog--center .el-dialog__footer {
  text-align: center;
}

::v-deep .el-form-item__error {
  color: #f56c6c;
  font-size: 14px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 30%;
  left: 70%;
}
::v-deep input {
  background-color: black;
  border: none;
  color: white;
  outline: none;
  /* font-size: 0.790123em; */
  font-family: "Roboto", sans-serif;
  width: 100%;
}

.dFlex-column {
  display: flex;
  flex-direction: column;
}
.input-text-tablet {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0.790123em;
  font-family: "Roboto", sans-serif;
  color: white;
  font-weight: 400;
}

.oveflow-hidden {
  overflow-x: hidden;
}

.btn-tablet {
  background-color: #eb2629;
  color: white;
  border-radius: 50px;
  font-size: 1em;
  width: 274px;
  border-color: black;
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contact-text-tablet {
  color: white;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.project-text-tablet {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: white;
  font-style: normal;
  font-size: 1.23077em;
  line-height: 18.75px;
}

.project-title-tablet {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: white;
  font-style: normal;
  font-size: 1.23077em;
  line-height: 18.75px;
}

.red-number-tablet {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #eb2629;
  font-style: normal;
  font-size: 3.7em;
  line-height: 56.25px;
}

.details-title-tablet {
  color: white;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.84615em;
  line-height: 28.13px;
  text-align: center;
}

.details-tablet {
  color: white;
  text-decoration: underline;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
}

.small-description-tablet {
  color: white;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
  line-height: 18.75px;
}

.description-benefits-tablet {
  color: white;
  font-family: "Roboto", sans-serif;
  line-height: 21.09px;
  font-weight: 500;
  font-style: normal;
  font-size: 1.125em;
}

.description-white-tablet {
  color: white;
  font-family: "Roboto", sans-serif;
  line-height: 21.09px;
  font-weight: 500;
  font-style: normal;
  font-size: 1.125em;
}

.description-red-tablet {
  margin-top: 3.818vh;
  font-family: "Roboto", sans-serif;
  color: #eb2629;
  line-height: 21.09px;
  font-weight: 500;
  font-style: normal;
  font-size: 1.125em;
}
.section-first-main-tablet {
  background-image: url(../assets/third-bg-mobile.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
}

.sharingan-img-tablet {
  width: 68.533vw;
  height: 34.852vh;
}

.main-title-tablet {
  margin-top: 3.818vh;
  font-family: "Roboto", sans-serif;
  color: white;
  line-height: 32.81px;
  font-weight: 700;
  font-style: normal;
  font-size: 1.75em;
  text-align: center;
}

.section-3-container-tablet {
  padding-right: 4.267vw;
  padding-left: 4.267vw;
}

.input-text {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  color: white;
  font-weight: 400;
}

::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  display: inline-block;
  cursor: default;
  flex: 0 0 auto;
  align-self: stretch;
  /* -webkit-user-select: none; */
  opacity: 0;
  pointer-events: none;
  -webkit-user-modify: read-only;
}

.dividerForm {
  margin-top: 5px;
}

::v-deep .el-dialog--center .el-dialog__body {
  text-align: initial;
  padding-right: 4.514vw;
  padding-left: 4.514vw;
}

::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: -40px;
  right: -40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: black;
  font-size: 1.5em;
  padding: 10px;
  border-radius: 50%;
  background-color: white;
}

::v-deep .el-select .el-input .el-select__caret {
  color: red;
  font-size: 1.125em;
  transition: transform 0.3s;
  transform: rotateZ(180deg);
  cursor: pointer;
}

::v-deep .el-textarea__inner {
  min-height: 165px !important;
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  color: white;
  background-color: black;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep .el-select-dropdown__item {
  font-size: 1.125em !important;
  font-family: "Roboto", sans-serif;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}

::v-deep .el-input {
  position: relative;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  width: 100%;
}

::v-deep .el-dialog,
.el-pager li {
  background: black;
}

::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 50%;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: black;
  background-image: none;
  border: none;
  box-sizing: border-box;
  color: white;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

::v-deep .el-dialog__header {
  padding: 20px 20px 10px;
}
::v-deep .el-dialog__body {
  padding: 30px 20px;
  background-color: black;
  color: #606266;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  word-break: inherit;
}

::v-deep .el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

::v-deep .el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  margin-left: 0px !important;
  width: 100%;
}
.dFlex {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item__label {
  text-align: start;
  vertical-align: middle;
  float: left;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #eb2629;
  line-height: 20px;
  padding: 0 12px 0 0;
  margin-bottom: 0px;
  box-sizing: border-box;
}

::v-deep .el-dialog__title {
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  font-weight: 500;
  color: white;
}
.btn-red {
  background-color: #eb2629;
  color: white;
  border-radius: 50px;
  font-size: 1.125em;
  width: 274px;
  border-color: black;
  margin-bottom: 30px;
}

::v-deep .el-divider--vertical {
  display: inline-block;
  width: 3px;
  height: 50px;
  margin: 0 8px;
  vertical-align: middle;
  position: relative;
  background-color: white;
}
.center {
  text-align: center;
}

.details-title {
  color: white;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2em;
  line-height: 28px;
  text-align: center;
}

::v-deep .el-collapse-item__wrap {
  will-change: height;
  background-color: black;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: none;
}
::v-deep .el-collapse-item__arrow {
  display: none;
}

::v-deep .el-collapse {
  border-top: none;
  border-bottom: none;
}

::v-deep .el-collapse-item__header {
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  background-color: black;
  color: #303133;
  cursor: pointer;
  border-bottom: none;
  font-size: 1em;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
  text-align: center;
  justify-content: center;
}

.server-margin {
  margin-top: 29px;
}

.project-text {
  font-size: 1.385em;
  color: white;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 21.09px;
  margin-top: 0;
  margin-bottom: 0;
}
.project-title {
  font-size: 1.385em;
  color: white;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-top: 0;
  margin-bottom: 9px;
}

.red-number {
  color: red;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 3.7em;
  margin-top: 0;
  margin-bottom: 0;
}

.contact-us {
  text-align: center;
}
.contact-text {
  color: white;
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.btn {
  background-color: #eb2629;
  color: white;
  border-radius: 50px;
  font-size: 1.125em;
  width: 274px;
  border-color: black;
  margin-bottom: 30px;
}

.details {
  color: white;
  text-decoration: underline;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
}

.small-description-container {
  margin-right: 74px;
  margin-left: 74px;
}

.small-description-2 {
  color: white;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 0;
  line-height: 21.09px;
}
.small-description {
  color: white;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
  line-height: 21.09px;
}

.sharingan-img {
  width: 80%;
}

.description-red {
  color: red;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 1.5em;
  font-style: normal;
}
.description-white {
  color: white;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 1.5em;
  font-style: normal;
}
.description-benefits {
  color: white;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 1.5em;
  font-style: normal;
}

.third-page-container {
  margin-left: 10.764vw;
  margin-right: 10.764vw;
}

.section-third-main {
  background-image: url(../assets/third.png);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right top;
  height: 100%;
  width: 100%;
  /* float: right; */
}
.main-title {
  color: white;
  font-size: 2.25em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-top: 0px;
  text-align: center;
}
.sever-img {
  float: right;
}

.section-3 {
  background-color: black;
  width: 100vw;
  height: 100%;
  /* padding-top: 67px; */
}

@media only screen and (max-width: 991px) {
  ::v-deep .el-form-item__error {
    color: #f56c6c;
    font-size: 14px;
    line-height: 1;
    padding-top: 4px;
    position: initial;
    /* top: 30%; */
    /* left: 70%; */
    text-align: center;
  }
  ::v-deep .el-dialog__title {
    line-height: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 1.125em;
    font-weight: 500;
    color: white;
  }

  ::v-deep .el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    margin: 0;
  }

  ::v-deep .el-form-item__label {
    text-align: start;
    vertical-align: middle;
    float: left;
    font-size: 0.88889em;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #eb2629;
    line-height: 20px;
    padding: 0 12px 0 0;
    margin-bottom: 0px;
    box-sizing: border-box;
  }
  ::v-deep .v-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0 !important;
    background: black !important;
  }

  .el-input {
    position: relative;
    font-size: 0.790123em;
    font-family: "Roboto", sans-serif;
    display: inline-block;
    width: 100%;
  }
  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: -50px;
    right: 5px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }

  .section-first-main-tablet {
    background-image: url(../assets/third-bg-mobile.png);
    background-repeat: no-repeat;
    background-size: 50%;
    width: 100%;
    height: 100%;
    background-position-x: right;
  }

  ::v-deep input {
    background-color: black;
    border: none;
    color: white;
    outline: none;
    font-size: 0.790123em;
    font-family: "Roboto", sans-serif;
    width: 100%;
  }
}
</style>

<script>
import MaskedInput from "vue-masked-input";
export default {
  name: "ThirdPage",
  data() {
    return {
      centerDialogVisible: false,
      ruleForm: {
        name: "",
        variants: "",
        email: "",
        phone: "",
        desc: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Введите свое Имя",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "Имя должно содержать от 3 до 20 символов",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Введите вашу почту",
            trigger: "blur",
          },
          {
            min: 3,
            max: 30,
            message: "Почта должна содержать от 3 до 30 символов",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Введите ваш телефон",
            trigger: "blur",
          },
          {
            min: 11,
            message: "Номер телефона введен некорректно",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("Работает?");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  components: {
    MaskedInput,
  },
};
</script>
