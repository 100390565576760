<template>
  <div class="oveflow-hidden">
    <div class="d-sm-block d-mb-block d-lg-none">
      <div class="section-4">
        <div class="section-3-container-tablet">
          <div class="center">
            <el-divider direction="vertical"></el-divider>
          </div>
          <h1 class="main-title-tablet">Создание проектной среды</h1>
          <p class="main-title-description-tablet">
            Компания «DragAu» предлагает комплексные услуги по созданию и
            поддержке единой проектной среды в организации. Единая проектная
            среда строится на основе методологии управления проектами PRINCE2.
            Процесс создания контролируемой проектной среды охватывает 3
            области:
          </p>
          <el-row :gutter="10" class="small-container">
            <el-col :span="24">
              <div class="">
                <p class="project-environment">ЛЮДИ</p>
                <el-divider
                  class="project-environment-divider-tablet"
                ></el-divider>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="">
                <p class="project-environment">ПРОЦЕССЫ</p>
                <el-divider
                  class="project-environment-divider-tablet"
                ></el-divider>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="">
                <p class="project-environment">ТЕХНОЛОГИИ</p>
                <el-divider
                  class="project-environment-divider-tablet"
                ></el-divider>
              </div>
            </el-col>
          </el-row>
          <div class="">
            <el-collapse class="">
              <el-collapse-item>
                <template class="collapse-flex" slot="title">
                  <div>
                    <p class="red-number-tablet">1</p>
                  </div>
                  <div class="line-height-collapse">
                    <p class="collapse-text-tablet mb-0">
                      <strong>Под областью «Люди»</strong> понимается подготовка
                      и обучение персонала компании. Компания «DragAu» проводит
                      обучение по программам PRINCE2 Foundation и PRINCE2
                      Practitioner в 3 формах:
                    </p>
                    <ul class="collapse-text-tablet pl-4">
                      <li>Онлайн обучение</li>
                      <li>Удаленное обучение</li>
                      <li>Очное обучение</li>
                    </ul>
                  </div>
                </template>
                <div class="">
                  <p class="collapse-text marginTop">
                    Онлайн обучение проводится на специально разработанном
                    совместно с партнерами портале в сети Интернет.При
                    прохождении обучения в формате Online, место, время и
                    продолжительность занятий онлайн курса регилируется
                    слушателями самостоятельно.
                  </p>
                  <p class="collapse-text">
                    С помощью онлайн курса слушатель знакомится с методом
                    PRINCE2. И получает достаточные знания и понимание метода
                    PRINCE2, чтобы иметь возможность эффективно работать с
                    командой управления проектом или в качестве члена группы,
                    работающин в среде, поддерживающей PRINCE2. По завершению
                    обучения слушатель сдает сертификационный экзамен.
                  </p>
                  <p class="collapse-text">
                    При удаленном обучении, тренер проводит обучение удаленно в
                    режиме реального времени. Слушатели подключаются к эфиру
                    посредством современных средств видеокоммуникаций.
                    Приемуществом данного способа обучение является возможность
                    прямых коммуникаций слушателя с тренером и получение
                    дополнительных комментариев по возникшим вопросам.Подобный
                    способ может быть интересен группам, в которых слушатели
                    находятся в разных городах или офисах.
                  </p>
                  <p class="collapse-text-mb-0">
                    Обучение также может проводится классическим способом в
                    форме очного обучения при личном присутствии тренера. Такие
                    обучающие сессии могут быть организованы как на территории
                    Заказчика, так и в других локациях. Прием экзаменов по
                    программам PRINCE2 Foundation и PRINCE2 Practitioner может
                    проводится в режиме online или в бумажном виде в присутствии
                    экзаменатора.
                  </p>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>

          <div class="second">
            <el-collapse class="">
              <el-collapse-item>
                <template class="collapse-flex" slot="title">
                  <div>
                    <p class="red-number-tablet-2">2</p>
                  </div>
                  <div class="line-height-collapse">
                    <p class="collapse-text-tablet">
                      <strong>Под областью «Процессы»</strong> понимается
                      внедрение методологии проектного управления PRINCE2 в
                      организации и ее адаптация для конкретного клиента.<br />В
                      рамках внедрения методологии специалисты компании «DragAu»
                      разрабатывают пакет документов для создания и внедрения
                      единой проектной среды, позволяющей организации управлять
                      своими проектами в соответствии с методом PRINCE2.
                    </p>
                  </div>
                </template>
                <div class="">
                  <p class="collapse-text marginTop">
                    В разработанный пакет документов входят:
                  </p>
                  <ul class="collapse-text ul-marginbot">
                    <li>Регламенты и процедуры;</li>
                    <li>Организационная структура;</li>
                    <li>Описание ролей;</li>
                    <li>Должностные инструкции;</li>
                    <li>Шаблоны документов;</li>
                    <li>Шаблоны отчетов.</li>
                  </ul>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="third">
            <el-collapse class="third">
              <el-collapse-item>
                <template class="collapse-flex" slot="title">
                  <div>
                    <p class="red-number-tablet-3">3</p>
                  </div>
                  <div class="line-height-collapse">
                    <p class="collapse-text-tablet">
                      <strong>В области «Технологии»</strong> подразумевается
                      использование организацией специализированной
                      информационной системы, разработанной специалистами нашей
                      компании – «Портфолио менеджмент – управление проектной
                      средой организации».
                    </p>
                  </div>
                </template>
                <div class="">
                  <p class="collapse-text marginTop">
                    Информационная система разработана в соответствии с
                    методологией управления проектами PRINCE2 и обеспечивает
                    автоматизацию процессов управления портфелем проектов
                    организации. Система представляет собой набор бизнес практик
                    и процессов, помогающих организации приоритезировать и
                    выстроить работу для достижения своих стратегических целей.
                    Система является централизованным источником получения и
                    анализа данных обо всех программах и проектах, существующих
                    в организации. На основании информации о проектах, Система
                    позволяет генерировать отчетность для всех уровней
                    управления.
                  </p>
                  <p class="collapse-text marginTop">
                    Функциональные области Информационной системы «Портфолио
                    менеджмент – управление проектной средой организации»:
                  </p>
                  <ul class="collapse-text ul-marginbot">
                    <li>Сбор идей по проектам;</li>
                    <li>Руководство и Управление проектами;</li>
                    <li>Руководство и Управление программами;</li>
                    <li>Руководство и Управление портфолио проектов;</li>
                    <li>Развитие экспертизы управления проектами;</li>
                    <li>Ресурсное планирование;</li>
                    <li>Реализация выгод;</li>
                    <li>Отчетность по всем функциональным областям.</li>
                  </ul>
                </div>
              </el-collapse-item>
            </el-collapse>

            <el-divider class="divider-top"></el-divider>

            <div class="contact-us">
              <p class="contact-text-tablet">Если вам это интересно</p>
              <!-- <el-button class="btn">Напишите нам</el-button> -->
              <button class="btn-tablet">
                <a
                  class="mailto-text"
                  href="mailto:info@dragau.kz?subject=Создание проектной среды!"
                  >Напишите нам</a
                >
              </button>
              <!-- <el-button class="btn-tablet" @click="centerDialogVisible = true"
                >Напишите нам</el-button
              > -->
              <el-dialog
                title="Напишите нам"
                :visible.sync="centerDialogVisible"
                width="93.600vw"
                center
              >
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                  <el-form-item class="dFlex" label="Кому:">
                    <p class="input-text-tablet">info@dragau.kz</p>
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item class="dFlex" label="Имя:" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item class="dFlex" label="От:" prop="email">
                    <el-input
                      placeholder="Введите свой email"
                      v-model="ruleForm.email"
                    ></el-input>
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item class="dFlex" label="Телефон:" prop="phone">
                    <masked-input
                      v-model="ruleForm.phone"
                      mask="\+\7 (111) 111-11-11"
                      placeholder="+7 ("
                    />
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item
                    class="dFlex-column"
                    label="Тема сообщения:"
                    prop="variants"
                  >
                    <p class="input-text-tablet">Создание проектной среды</p>
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item label="Сообщение:" prop="desc">
                    <el-input
                      type="textarea"
                      style="margin-top: 10px"
                      v-model="ruleForm.desc"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item>
                                <el-button type="primary" @click="onSubmit">Create</el-button>
                            </el-form-item> -->
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <!-- <el-button @click="centerDialogVisible = false">Cancel</el-button> -->
                  <el-button class="btn-red" @click="submitForm('ruleForm')"
                    >Отправить</el-button
                  >
                </span>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="section-4">
        <div class="four-page-container">
          <div class="center">
            <el-divider direction="vertical"></el-divider>
          </div>
          <h1 class="main-title">Создание проектной среды</h1>
          <p class="main-title-description">
            Компания «DragAu» предлагает комплексные услуги по созданию и
            поддержке единой проектной среды в организации. Единая проектная
            среда строится на основе методологии управления проектами PRINCE2.
            Процесс создания контролируемой проектной среды охватывает 3
            области:
          </p>
          <el-row :gutter="10" class="small-container">
            <el-col :span="8">
              <div class="">
                <p class="project-environment">ЛЮДИ</p>
                <el-divider class="project-environment-divider"></el-divider>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="">
                <p class="project-environment">ПРОЦЕССЫ</p>
                <el-divider class="project-environment-divider"></el-divider>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="">
                <p class="project-environment">ТЕХНОЛОГИИ</p>
                <el-divider class="project-environment-divider"></el-divider>
              </div>
            </el-col>
          </el-row>
          <div class="">
            <el-collapse class="">
              <el-collapse-item>
                <template class="collapse-flex" slot="title">
                  <div>
                    <p class="red-number">1</p>
                  </div>
                  <div class="line-height-collapse">
                    <p class="collapse-text">
                      <strong>Под областью «Люди»</strong> понимается подготовка
                      и обучение персонала компании. Компания «DragAu» проводит
                      обучение по программам PRINCE2 Foundation и PRINCE2
                      Practitioner в 3 формах:
                    </p>
                    <ul class="collapse-text">
                      <li>Онлайн обучение</li>
                      <li>Удаленное обучение</li>
                      <li>Очное обучение</li>
                    </ul>
                  </div>
                </template>
                <div class="mcustom">
                  <p class="collapse-text marginTop">
                    Онлайн обучение проводится на специально разработанном
                    совместно с партнерами портале в сети Интернет.При
                    прохождении обучения в формате Online, место, время и
                    продолжительность занятий онлайн курса регилируется
                    слушателями самостоятельно.
                  </p>
                  <p class="collapse-text">
                    С помощью онлайн курса слушатель знакомится с методом
                    PRINCE2. И получает достаточные знания и понимание метода
                    PRINCE2, чтобы иметь возможность эффективно работать с
                    командой управления проектом или в качестве члена группы,
                    работающин в среде, поддерживающей PRINCE2. По завершению
                    обучения слушатель сдает сертификационный экзамен.
                  </p>
                  <p class="collapse-text">
                    При удаленном обучении, тренер проводит обучение удаленно в
                    режиме реального времени. Слушатели подключаются к эфиру
                    посредством современных средств видеокоммуникаций.
                    Приемуществом данного способа обучение является возможность
                    прямых коммуникаций слушателя с тренером и получение
                    дополнительных комментариев по возникшим вопросам.Подобный
                    способ может быть интересен группам, в которых слушатели
                    находятся в разных городах или офисах.
                  </p>
                  <p class="collapse-text-mb-0">
                    Обучение также может проводится классическим способом в
                    форме очного обучения при личном присутствии тренера. Такие
                    обучающие сессии могут быть организованы как на территории
                    Заказчика, так и в других локациях. Прием экзаменов по
                    программам PRINCE2 Foundation и PRINCE2 Practitioner может
                    проводится в режиме online или в бумажном виде в присутствии
                    экзаменатора.
                  </p>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>

          <div class="second">
            <el-collapse class="">
              <el-collapse-item>
                <template class="collapse-flex" slot="title">
                  <div>
                    <p class="red-number">2</p>
                  </div>
                  <div class="line-height-collapse">
                    <p class="collapse-text-mb-0">
                      <strong>Под областью «Процессы»</strong> понимается
                      внедрение методологии проектного управления PRINCE2 в
                      организации и ее адаптация для конкретного клиента.<br />В
                      рамках внедрения методологии специалисты компании «DragAu»
                      разрабатывают пакет документов для создания и внедрения
                      единой проектной среды, позволяющей организации управлять
                      своими проектами в соответствии с методом PRINCE2.
                    </p>
                  </div>
                </template>
                <div class="pcustom mcustom">
                  <p class="collapse-text marginTop">
                    В разработанный пакет документов входят:
                  </p>
                  <ul class="collapse-text ul-marginbot">
                    <li>Регламенты и процедуры;</li>
                    <li>Организационная структура;</li>
                    <li>Описание ролей;</li>
                    <li>Должностные инструкции;</li>
                    <li>Шаблоны документов;</li>
                    <li>Шаблоны отчетов.</li>
                  </ul>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="third">
            <el-collapse class="third">
              <el-collapse-item>
                <template class="collapse-flex" slot="title">
                  <div>
                    <p class="red-number">3</p>
                  </div>
                  <div class="line-height-collapse">
                    <p class="collapse-text-mb-0">
                      <strong>В области «Технологии»</strong> подразумевается
                      использование организацией специализированной
                      информационной системы, разработанной специалистами нашей
                      компании – «Портфолио менеджмент – управление проектной
                      средой организации».
                    </p>
                  </div>
                </template>
                <div class="pcustom mcustom">
                  <p class="collapse-text marginTop">
                    Информационная система разработана в соответствии с
                    методологией управления проектами PRINCE2 и обеспечивает
                    автоматизацию процессов управления портфелем проектов
                    организации. Система представляет собой набор бизнес практик
                    и процессов, помогающих организации приоритезировать и
                    выстроить работу для достижения своих стратегических целей.
                    Система является централизованным источником получения и
                    анализа данных обо всех программах и проектах, существующих
                    в организации. На основании информации о проектах, Система
                    позволяет генерировать отчетность для всех уровней
                    управления.
                  </p>
                  <p class="collapse-text marginTop">
                    Функциональные области Информационной системы «Портфолио
                    менеджмент – управление проектной средой организации»:
                  </p>
                  <ul class="collapse-text ul-marginbot">
                    <li>Сбор идей по проектам;</li>
                    <li>Руководство и Управление проектами;</li>
                    <li>Руководство и Управление программами;</li>
                    <li>Руководство и Управление портфолио проектов;</li>
                    <li>Развитие экспертизы управления проектами;</li>
                    <li>Ресурсное планирование;</li>
                    <li>Реализация выгод;</li>
                    <li>Отчетность по всем функциональным областям.</li>
                  </ul>
                </div>
              </el-collapse-item>
            </el-collapse>

            <el-divider class="divider-top"></el-divider>

            <div class="contact-us">
              <p class="contact-text">Если вам это интересно</p>
              <!-- <el-button class="btn">Напишите нам</el-button> -->
              <button class="btn">
                <a
                  class="mailto-text"
                  href="mailto:info@dragau.kz?subject=Создание проектной среды!"
                  >Напишите нам</a
                >
              </button>
              <!-- <el-button class="btn" @click="centerDialogVisible = true"
                >Напишите нам</el-button
              > -->
              <el-dialog
                title="Напишите нам"
                :visible.sync="centerDialogVisible"
                width="50%"
                center
              >
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                  <el-form-item class="dFlex" label="Кому:">
                    <p class="input-text">info@dragau.kz</p>
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item class="dFlex" label="Имя:" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item class="dFlex" label="От:" prop="email">
                    <el-input
                      placeholder="Введите свой email"
                      v-model="ruleForm.email"
                    ></el-input>
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item class="dFlex" label="Телефон:" prop="phone">
                    <masked-input
                      v-model="ruleForm.phone"
                      mask="\+\7 (111) 111-11-11"
                      placeholder="+7 ("
                    />
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item
                    class="dFlex"
                    label="Тема сообщения:"
                    prop="variants"
                  >
                    <p class="input-text">Создание проектной среды</p>
                  </el-form-item>
                  <el-divider class="dividerForm"></el-divider>
                  <el-form-item label="Сообщение:" prop="desc">
                    <el-input
                      type="textarea"
                      style="margin-top: 10px"
                      v-model="ruleForm.desc"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item>
                                <el-button type="primary" @click="onSubmit">Create</el-button>
                            </el-form-item> -->
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <!-- <el-button @click="centerDialogVisible = false">Cancel</el-button> -->
                  <el-button class="btn-red" @click="submitForm('ruleForm')"
                    >Отправить</el-button
                  >
                </span>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.btn-tablet :hover {
  background-color: black !important;
  color: white;
}

.btn :hover {
  background-color: black !important;
  color: white;
}
.mailto-text {
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  color: white;
  text-decoration: none;
}
::v-deep .el-dialog--center .el-dialog__footer {
  text-align: center;
}

::v-deep .el-form-item__error {
  color: #f56c6c;
  font-size: 14px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 30%;
  left: 70%;
}
::v-deep input {
  background-color: black;
  border: none;
  color: white;
  outline: none;
  /* font-size: 0.790123em; */
  font-family: "Roboto", sans-serif;
  width: 100%;
}
.dFlex-column {
  display: flex;
  flex-direction: column;
}
.input-text-tablet {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0.790123em;
  font-family: "Roboto", sans-serif;
  color: white;
  font-weight: 400;
}
.oveflow-hidden {
  overflow-x: hidden;
}
.contact-text-tablet {
  color: white;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.btn-tablet {
  background-color: black;
  color: white;
  border-radius: 50px;
  font-size: 1em;
  width: 274px;
  border-color: black;
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}
.collapse-text-tablet {
  font-weight: 400;
  font-size: 0.88889em;
  font-family: "Roboto", sans-serif;
  line-height: 18.75px;
}

.red-number-tablet-3 {
  font-weight: 700;
  font-size: 9.9643125em;
  line-height: 187px;
  color: #eb2629;
  font-family: "Roboto", sans-serif;
  /* margin-top: -90px; */
  margin-bottom: 0px;
  margin-left: -35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.red-number-tablet-2 {
  font-weight: 700;
  font-size: 9.9643125em;
  line-height: 187px;
  color: #eb2629;
  font-family: "Roboto", sans-serif;
  /* margin-top: -190px; */
  margin-bottom: 0px;
  margin-left: -35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.red-number-tablet {
  font-weight: 700;
  font-size: 9.9643125em;
  line-height: 187px;
  color: #eb2629;
  font-family: "Roboto", sans-serif;
  /* margin-top: 90px; */
  margin-bottom: 0px;
  margin-left: -35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.project-environment-divider-tablet {
  width: 16vw;
  text-align: center;
  margin: auto;
  margin-bottom: 3.859vh;
}
.main-title-description-tablet {
  color: white;
  font-family: "Roboto", sans-serif;
  line-height: 21.09px;
  font-weight: 500;
  font-style: normal;
  font-size: 1.125em;
  text-align: center;
}

.main-title-tablet {
  margin-top: 3.818vh;
  font-family: "Roboto", sans-serif;
  color: white;
  line-height: 32.81px;
  font-weight: 700;
  font-style: normal;
  font-size: 1.75em;
  text-align: center;
}
.section-3-container-tablet {
  padding-right: 4.267vw;
  padding-left: 4.267vw;
}

.input-text {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  color: white;
  font-weight: 400;
}

::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  display: inline-block;
  cursor: default;
  flex: 0 0 auto;
  align-self: stretch;
  /* -webkit-user-select: none; */
  opacity: 0;
  pointer-events: none;
  -webkit-user-modify: read-only;
}

.dividerForm {
  margin-top: 5px;
}

::v-deep .el-dialog--center .el-dialog__body {
  text-align: initial;
  padding-right: 4.514vw;
  padding-left: 4.514vw;
}

::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: -40px;
  right: -40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: black;
  font-size: 1.5em;
  padding: 10px;
  border-radius: 50%;
  background-color: white;
}

::v-deep .el-select .el-input .el-select__caret {
  color: red;
  font-size: 1.125em;
  transition: transform 0.3s;
  transform: rotateZ(180deg);
  cursor: pointer;
}

::v-deep .el-textarea__inner {
  min-height: 165px !important;
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  color: white;
  background-color: black;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep .el-select-dropdown__item {
  font-size: 1.125em !important;
  font-family: "Roboto", sans-serif;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}

::v-deep .el-input {
  position: relative;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  width: 100%;
}

::v-deep .el-dialog,
.el-pager li {
  background: black;
}

::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 50%;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: black;
  background-image: none;
  border: none;
  box-sizing: border-box;
  color: white;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

::v-deep .el-dialog__header {
  padding: 20px 20px 10px;
}
::v-deep .el-dialog__body {
  padding: 30px 20px;
  background-color: black;
  color: #606266;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  word-break: inherit;
}

::v-deep .el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

::v-deep .el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  margin-left: 0px !important;
  width: 100%;
}
.dFlex {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item__label {
  text-align: start;
  vertical-align: middle;
  float: left;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #eb2629;
  line-height: 20px;
  padding: 0 12px 0 0;
  margin-bottom: 0px;
  box-sizing: border-box;
}

::v-deep .el-dialog__title {
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  font-weight: 500;
  color: white;
}
.btn-red {
  background-color: #eb2629;
  color: white;
  border-radius: 50px;
  font-size: 1.125em;
  width: 274px;
  border-color: black;
  margin-bottom: 30px;
}

::v-deep .el-divider--vertical {
  display: inline-block;
  width: 3px;
  height: 50px;
  margin: 0 8px;
  vertical-align: middle;
  position: relative;
  background-color: white;
}
.center {
  text-align: center;
}

.divider-top {
  margin-top: 5.093vh;
}

.contact-us {
  text-align: center;
}

.contact-text {
  color: white;
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.btn {
  background-color: black;
  color: white;
  border-radius: 50px;
  font-size: 1.125em;
  width: 274px;
  border-color: black;
  margin-bottom: 50px;
}

.ul-marginbot {
  margin-bottom: 0px;
}

.third {
  margin-top: 4.931vh;
}
.second {
  margin-top: 4.931vh;
}

/* .unselectable{
 user-select: none;   
} */
/* .red-number-2{
    font-weight: 700;
    font-size: 15.9375em;
    color: white;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0px;
    margin-left: -24px;
} */
.marginTop {
  margin-top: 0;
}

::v-deep .el-collapse-item__wrap {
  will-change: height;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: none;
}

::v-deep .el-collapse {
  border-top: 5.073vh solid #fff;
  border-bottom: 5.073vh solid #fff;
  border-radius: 30px;
  background: white;
  /* height: 22.130vh; */
}
::v-deep .el-collapse-item__arrow {
  margin: 0 8px 0 auto;
  transition: transform 0.3s;
  font-weight: 300;
  width: 2em;
}

.line-height-collapse {
  line-height: 24px;
}

::v-deep .el-collapse-item__content {
  padding-bottom: 0px;
  font-size: 1.125em;
  color: #303133;
  line-height: 24.9px;
  /* margin-left: 8.819vw;
  margin-right: 3.056vw; */
}

::v-deep .el-collapse-item__header {
  display: flex;
  align-items: center;
  height: 18.667vh;
  line-height: 0px;
  background-color: #fff;
  color: #303133;
  cursor: pointer;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
  margin-top: 3.724vh;
  font-size: 1.125em;
  border-bottom: none;
  /* margin-bottom: 3.724vh; */
  margin-top: 0px;
  margin-bottom: 0px;
}

/* ::v-deep .el-collapse-item__header :active {
   
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: green ;

}  */
/* ::v-deep .el-collapse-item__content :active{
    padding-bottom: 25px;
    font-size: 13px;
    color: #303133;
    line-height: 1.769230769230769;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
} */

.collapse-text {
  font-weight: 400;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
}
.collapse-text-mb-0 {
  font-weight: 400;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}

.red-number {
  font-weight: 700;
  font-size: 15.9375em;
  color: #eb2629;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -35px;
  user-select: none;
}

.collapse-flex {
  display: flex;
}
.project-environment-divider {
  width: 4.167vw;
  text-align: center;
  margin: auto;
  margin-bottom: 3.859vh;
}

.small-container {
  margin-right: 5.208vw;
  margin-left: 5.208vw;
}

.project-environment {
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  font-size: 2.25em;
  font-style: normal;
  color: white;
  line-height: 42.19px;
  text-align: center;
  margin-bottom: 9px;
  text-transform: uppercase;
}

.main-title-description {
  color: white;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 1.5em;
  font-style: normal;
  margin-right: 5.208vw;
  margin-left: 5.208vw;
  text-align: center;
}

.main-title {
  color: white;
  font-size: 2.25em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-top: 0px;
  text-align: center;
}

.four-page-container {
  margin-left: 10.764vw;
  margin-right: 10.764vw;
}
.section-4 {
  background-color: #eb2629;
  width: 100vw;
  height: 100%;
  /* padding-top: 67px; */
}

@media only screen and (min-width: 1401px) and (max-width: 1920px) {
  ::v-deep .el-collapse-item__content {
    padding-bottom: 0px;
    font-size: 1.125em;
    color: #303133;
    line-height: 24.9px;
    /* margin-left: 6.615vw;
    margin-right: 3.056vw; */
  }
  .mcustom {
    margin-left: 124px;
    margin-right: 3vw;
    margin-top: 3vh;
  }

  ::v-deep .el-collapse {
    border-top: 60px solid #fff;
    border-bottom: 60px solid #fff;
    border-radius: 30px;
    background: white;
    /* height: 22.13vh; */
  }
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
  ::v-deep .el-collapse-item__wrap {
    will-change: height;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom: none;
    /* margin-left: 9.819vw;
    margin-right: 2vw; */
  }
  .mcustom {
    margin-left: 124px;
    margin-right: 3vw;
    margin-top: 5vh;
  }
  ::v-deep .el-collapse {
    border-top: 60px solid #fff;
    border-bottom: 60px solid #fff;
    border-radius: 30px;
    background: white;
    /* height: 22.13vh; */
  }
}

@media only screen and (max-width: 1181px) {
  /* ::v-deep .el-collapse-item__content {
    padding-bottom: 0px;
    font-size: 1.125em;
    color: #303133;
    line-height: 24.9px;
    margin-left: 12.819vw;
    margin-right: 3.056vw;
    margin-top: 3vh;
  } */
}

/* .pcustom {
  margin-left: 1vw;
} */

@media only screen and (max-width: 991px) {
  ::v-deep .el-form-item__error {
    color: #f56c6c;
    font-size: 14px;
    line-height: 1;
    padding-top: 4px;
    position: initial;
    /* top: 30%; */
    /* left: 70%; */
    text-align: center;
  }
  ::v-deep input {
    background-color: black;
    border: none;
    color: white;
    outline: none;
    /* font-size: 0.790123em; */
    font-family: "Roboto", sans-serif;
    width: 100%;
  }

  ::v-deep input {
    background-color: black;
    border: none;
    color: white;
    outline: none;
    font-size: 0.790123em;
    font-family: "Roboto", sans-serif;
  }
  ::v-deep .el-collapse-item__header {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 0px;
    background-color: #fff;
    color: #303133;
    cursor: pointer;
    font-weight: 500;
    transition: border-bottom-color 0.3s;
    outline: 0;
    margin-top: 3.724vh;
    /* font-size: 1.125em; */
    border-bottom: none;
    /* margin-bottom: 3.724vh; */
    margin-top: 0px;
    margin-bottom: 0px;
  }

  ::v-deep .el-collapse {
    border-top: 14px solid white;
    border-bottom: 14px solid white;
    border-radius: 30px;
    background: white;
    /* height: 22.13vh; */
    border-right: 11px solid white;
    border-left: 11px solid white;
  }
  ::v-deep .el-collapse-item__content {
    padding-bottom: 0px;
    font-size: 0.88889em;
    color: #303133;
    line-height: 18.75px;
    margin-left: 0px;
    margin-right: 0px;
  }
  ::v-deep .el-dialog__title {
    line-height: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 1.125em;
    font-weight: 500;
    color: white;
  }

  ::v-deep .el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    margin: 0;
  }

  ::v-deep .el-form-item__label {
    text-align: start;
    vertical-align: middle;
    float: left;
    font-size: 0.88889em;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #eb2629;
    line-height: 20px;
    padding: 0 12px 0 0;
    margin-bottom: 0px;
    box-sizing: border-box;
  }
  ::v-deep .v-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1 !important;
    background: black !important;
  }

  .el-input {
    position: relative;
    font-size: 0.790123em;
    font-family: "Roboto", sans-serif;
    display: inline-block;
    width: 100%;
  }
  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: -50px;
    right: 5px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }
}
</style>

<script>
import MaskedInput from "vue-masked-input";
export default {
  name: "FourPage",
  data() {
    return {
      centerDialogVisible: false,
      ruleForm: {
        name: "",
        variants: "",
        email: "",
        phone: "",
        desc: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Введите свое Имя",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "Имя должно содержать от 3 до 20 символов",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Введите вашу почту",
            trigger: "blur",
          },
          {
            min: 3,
            max: 30,
            message: "Почта должна содержать от 3 до 30 символов",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Введите ваш телефон",
            trigger: "blur",
          },
          {
            min: 11,
            message: "Номер телефона введен некорректно",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("Работает?");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  components: {
    MaskedInput,
  },
};
</script>