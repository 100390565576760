<template>
  <div class="oveflow-hidden">
    <!-- Добавить оферлоу х скрыть в каждом блоке-->
    <div class="d-sm-block d-mb-block d-lg-none">
      <vm-back-top :right="10"></vm-back-top>
      <div class="section-1">
        <div class="header">
          <div class="header-container-tablet">
            <el-row :gutter="0" class="">
              <el-col :span="12"
                ><div class="grid-content text-number">
                  <a class="mailto-text-header" href="tel:+77272411728"
                    >+7 (727) 241 17 28</a
                  >
                </div></el-col
              >
              <el-col :span="12"
                ><div class="grid-content text-info">
                  <a class="mailto-text-header" href="mailto:info@dragau.kz"
                    >info@dragau.kz</a
                  >
                </div></el-col
              >
            </el-row>
            <el-row :gutter="0" class="">
              <el-col :span="24"
                ><div class="dragImg-tablet">
                  <img
                    class="dragLogo-tablet"
                    src="../assets/dragLogo.svg"
                  /></div
              ></el-col>
            </el-row>
          </div>
        </div>
        <div class="section-first-main-tablet">
          <div class="main-content-tablet">
            <el-row class="">
              <el-col :span="24" class="main-margin-tablet">
                <div class="">
                  <p class="main-title-small-tablet">
                    <mark class="mark-tablet">консалтинговое бюро</mark> DRAGAU
                  </p>
                </div>
                <h3 class="main-title-tablet">
                  предоставляет <br />
                  услуги прикладного <br />
                  консалтинга
                </h3>
                <p class="description-tablet mb-0">
                  создавая комплексные решения для<br />
                  повышения эффективности управления <br />
                  бизнесом
                </p>
              </el-col>
            </el-row>
          </div>
          <div class="center">
            <el-divider direction="vertical"></el-divider>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <vm-back-top></vm-back-top>
      <div class="section-1">
        <!-- <vue-backTop><span>asdasd</span></vue-backTop> -->
        <div class="header">
          <div class="header-container">
            <el-row :gutter="0" class="header-content">
              <el-col :span="8"
                ><div class="grid-content text-number">
                  <a class="mailto-text-header" href="tel:+77272411728"
                    >+7 (727) 241 17 28</a
                  >
                </div></el-col
              >
              <el-col :span="8"
                ><div class="grid-content dragImg">
                  <img class="dragLogo" src="../assets/dragLogo.svg" /></div
              ></el-col>
              <el-col :span="8"
                ><div class="grid-content text-info">
                  <a class="mailto-text-header" href="mailto:info@dragau.kz"
                    >info@dragau.kz</a
                  >
                </div></el-col
              >
            </el-row>
            <el-divider class=""></el-divider>
            <el-row :gutter="0" class="header-content">
              <el-col :span="10"
                ><div class="grid-content-second text-first">
                  <a class="cursor" @click="$emit('SecondPage')"
                    >Интегрированные маркетинговые коммуникации</a
                  >
                </div></el-col
              >
              <el-col :span="7"
                ><div class="grid-content-second text-second">
                  <a class="cursor" @click="$emit('ThirdPage')"
                    >Создание и развитие продукта</a
                  >
                </div></el-col
              >
              <el-col :span="7"
                ><div class="grid-content-second text-third">
                  <a class="cursor" @click="$emit('FourPage')"
                    >Создание проектной среды</a
                  >
                </div></el-col
              >
            </el-row>
            <el-divider class="divider-mb"></el-divider>
          </div>
        </div>
        <div class="section-first-main">
          <div class="main-content">
            <el-row class="marginbBot">
              <el-col :span="11"><div class="">asdad</div></el-col>
              <el-col :span="13" class="main-margin">
                <div class="text-line">
                  <p class="main-title-small">
                    <mark>консалтинговое бюр</mark>о DRAGAU
                  </p>
                  <span class="red-line"></span>
                </div>
                <h3 class="main-title">
                  предоставляет <br />
                  услуги прикладного <br />
                  консалтинга
                </h3>
                <p class="description">
                  создавая комплексные решения для<br />
                  повышения эффективности управления <br />
                  бизнесом
                </p>
              </el-col>
            </el-row>
          </div>
          <div class="center">
            <el-divider direction="vertical"></el-divider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.mailto-text-header {
  color: white;
  text-align: start;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
}
.cursor {
  cursor: pointer;
  color: white;
}
.oveflow-hidden {
  overflow-x: hidden;
}

.description-tablet {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 0.875em;
  font-weight: 400;
  font-style: normal;
  line-height: 1.3em;
}

.main-title-tablet {
  color: white;
  text-transform: uppercase;
  font-size: 1.75em;
  font-weight: 700;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-top: 10px;
  line-height: 33px;
}

.main-title-small-tablet {
  color: white;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
}

.mark-tablet {
  background-color: red;
  color: white;
  line-height: 28px;
  padding-left: 0px;
  padding-right: 0px;
}

.main-content-tablet {
  margin-right: 2.267vw;
  margin-left: 4.267vw;
}
.main-margin-tablet {
  margin-top: 30.985vh;
  margin-bottom: 6.034vh;
}

.section-first-main-tablet {
  background-image: url(../assets/first-bg-mobile.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
}

.dragImg-tablet {
  padding-top: 2vh;
  text-align: center;
}

.dragLogo-tablet {
  width: 55.2vw;
}

.header-container-tablet {
  margin-right: 4.267vw;
  margin-left: 4.267vw;
}
/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */
::v-deep .el-divider--vertical {
  display: inline-block;
  width: 3px;
  height: 50px;
  margin: 0 8px;
  vertical-align: middle;
  position: relative;
  background-color: white;
}
.center {
  text-align: center;
}

.marginbBot {
  margin-bottom: 26.11vh;
}
.divider {
  height: 200px;
  width: 5px;
  background-color: red;
  display: inline-block;
}
.dividerTop {
  text-align: center;
}
mark {
  background-color: red;
  color: white;
  padding: 7px 2px 7px 22px;
}

.text-line {
  display: flex;
  align-items: center;
}
.main-margin {
  margin-top: 17.88vh;
}
.red-line {
  background-color: red;
  width: 14.59vw;
  margin-left: 15px;
  height: 7px;
}
.main-title-small {
  color: white;
  font-size: 2.25em;
  font-weight: 700;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
}
.main-title {
  color: white;
  text-transform: uppercase;
  font-size: 2.25em;
  font-weight: 700;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-top: 10px;
  margin-left: 1.3vw;
  line-height: 1.5em;
}
.description {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  font-style: normal;
  margin-left: 1.3vw;
  line-height: 1.3em;
}
.section-1 {
  background-color: black;
  width: 100vw;
  height: 100%;
}
.section-first-main {
  background-image: url(../assets/section-first-bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
}
.el-divider {
  background-color: white;
}

.divider-mb {
  margin-bottom: 0px;
}
.header-container {
  margin-left: 5.139vw;
  margin-right: 5.139vw;
}
.header-content {
  margin-right: 5.139vw;
  margin-left: 5.139vw;
}
.text-number {
  text-align: start;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
}
.text-first {
  text-align: start;
  font-family: "Roboto", sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  font-style: normal;
}
.text-second {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  font-style: normal;
}
.text-third {
  text-align: end;
  font-family: "Roboto", sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  font-style: normal;
}
.dragImg {
  text-align: center;
  padding-top: 38px !important;
}
.text-info {
  text-align: end;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  color: white !important;
}
.dragLogo {
  width: 13.8125em;
  height: 2.1875em;
}

.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  padding-top: 42px;
  color: white;
}
.grid-content-second {
  border-radius: 4px;
  color: white;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

@media only screen and (max-width: 991px) {
  ::v-deep .el-divider--vertical {
    display: inline-block;
    width: 3px;
    height: 30px;
    margin: 0 8px;
    vertical-align: middle;
    position: relative;
    background-color: white;
  }
}
</style>

<script>
import VmBackTop from "vue-multiple-back-top";
// import vueBacktopPack from 'vue-backtop-pack'
export default {
  name: "FirstPage",
  components: {
    // vueBacktopPack,
    VmBackTop,
  },
};
</script>
