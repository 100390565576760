<template>
  <div class="oveflow-hidden">
    <!-- Добавить оферлоу х скрыть в каждом блоке-->
    <div class="d-sm-block d-mb-block d-lg-none">
      <div class="section-2-tablet">
        <div class="">
          <div class="center section-2-container-tablet">
            <el-divider direction="vertical"></el-divider>
          </div>
          <h1 class="main-title-tablet section-2-container-tablet">
            Интегрированные <br />
            маркетинговые коммуникации
          </h1>
          <p class="main-description-tablet section-2-container-tablet">
            Интегрированные маркетинговые коммуникации - это взаимодействие с
            потребителями через различные каналы и формы коммуникации, при
            котором коммуникации в каждом из используемых каналов, взаимоувязаны
            между собой и подкрепляются друг другом для достижения максимальной
            эффективности.
          </p>
          <p class="second-description-tablet section-2-container-tablet">
            Подобный подход позволяет направлять различным целевым аудиториям
            точные,согласованные, маркетинговые сообщения, содействующие
            максимизации эффективности коммуникации.
          </p>
          <p class="content-text-tablet section-2-container-tablet">
            Использование концепции интегрированных маркетинговых <br />
            коммуникаций предполагает решение следующих задач:
          </p>
          <el-row class="section-2-container-tablet">
            <el-col :span="5" class="arrow-tablet">
              <el-divider class="arrow-divider-tablet"></el-divider>
              <i class="el-icon-arrow-right"></i>
            </el-col>
            <el-col class="ul-marginTop" :span="19">
              <div class="">
                <ul class="ul-text-tablet">
                  <li class="">
                    создание системы сообщений потребителям через различные
                    каналы и средства коммуникаций, которые координируются между
                    собой и создают целостный благориятный образ компании и её
                    продукта;
                  </li>
                  <li class="">
                    использование оптимальных комбинаций средств коммуникаций;
                  </li>
                  <li class="">
                    исключение противоречий между коммуникациями в отдельных
                    каналах с целью оказания более сильного влияния на
                    потребителя;
                  </li>
                  <li class="">
                    контроль и экономия средств за счет ведения учета затрат в
                    рамках каждой коммуникационной кампании;
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <div class="second-img-tablet section-2-container-tablet">
            <img src="../assets/code.png" class="code-img" alt="" />
          </div>
          <div class="section-2-container-tablet">
            <p class="img-text-content-tablet">
              Компания «DragAu» предоставляет комплексный инструмент,
              позволяющий клиентам компании реализовать концепцию
              интегрированных маркетинговых коммуникаций в отношении своих
              клиентов, потребителей, партнеров и т.д.
            </p>
          </div>

          <el-collapse class="">
            <el-collapse-item class="">
              <template class="collapse-flex" slot="title">
                <div class="">
                  <p class="details-tablet">
                    подробнее <i class="el-icon-bottom"></i>
                  </p>
                </div>
              </template>
              <div>
                <p class="details-title-tablet section-2-container-tablet">
                  Разработанные в компании автоматизированные комплексы
                  позволяют осуществлять интегрированные маркетинговые
                  коммуникации с использованием следующих каналов:
                </p>

                <el-row
                  class="items-center elcolmarginBot-tablet section-2-container-tablet"
                >
                  <el-col :span="12">
                    <div class="item-container">
                      <img src="../assets/web-monitor.svg" alt="" />
                      <p class="product-items mb-0 mt-3">Web-порталы</p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item-container">
                      <img src="../assets/telegram.svg" alt="" />
                      <p class="product-items mb-0 mt-3">Telegram-боты</p>
                    </div>
                  </el-col>
                </el-row>
                <el-row
                  class="items-center elcolmarginBot-tablet section-2-container-tablet"
                >
                  <el-col :span="12">
                    <div class="item-container">
                      <img src="../assets/mobile-phone.svg" alt="" />
                      <p class="product-items mb-0 mt-3">
                        Мобильные приложения
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item-container">
                      <img src="../assets/sms.svg" alt="" />
                      <p class="product-items mb-0 mt-3">SMS</p>
                    </div>
                  </el-col>
                </el-row>
                <el-row
                  class="items-center elcolmarginBot-tablet section-2-container-tablet"
                >
                  <el-col :span="12">
                    <div class="item-container">
                      <img
                        src="../assets/payment.svg"
                        class="payment-img"
                        alt=""
                      />
                      <p class="product-items mb-0 mt-3">Платежные системы</p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item-container">
                      <img src="../assets/mail.svg" alt="" />
                      <p class="product-items mb-0 mt-3">Электронная почта</p>
                    </div>
                  </el-col>
                </el-row>
                <el-row
                  class="items-center elcolmarginBot-tablet section-2-container-tablet"
                >
                  <el-col :span="24">
                    <div class="item-container">
                      <img src="../assets/phone.svg" alt="" />
                      <p class="product-items mb-0 mt-3">Телефония</p>
                    </div>
                  </el-col>
                </el-row>

                <el-row class="server-margin section-2-container-tablet">
                  <el-col :span="24">
                    <div class="">
                      <p class="server-text-main-tablet">
                        Автоматизированные комплексы разрабатываются
                        специалистами компании на базе промышленных
                        информационных систем, что позволяет обеспечить
                        надежность работы и безопастность данных.
                      </p>
                      <p class="server-text-description-tablet">
                        Соответствие предлагаемого решения принципам концепции
                        интегрированных маркетинговых коммуникаций
                        обеспечивается следующими функциональностями системы:
                      </p>
                      <ul class="server-ul">
                        <li class="server-text-description-tablet">
                          Информация обо всех активностях потребителя/продавца
                          во всех каналах централизовано хранится в единой
                          системе;
                        </li>
                        <li class="server-text-description-tablet">
                          Система является центром управления коммуникациями где
                          можно определить предпочтения потребителей по каналам,
                          языку и режиму коммуникации;
                        </li>
                        <li class="server-text-description-tablet">
                          Система является централизованным хранилищем шаблонов
                          для всех каналов коммуникаций;
                        </li>
                        <li class="server-text-description-tablet">
                          В системе настроены матрицы действий с прописанными
                          правилами действий при входящих коммуникациях в любом
                          канале.
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="">
                      <img
                        src="../assets/server.png"
                        class="sever-img"
                        alt=""
                      />
                    </div>
                  </el-col>
                </el-row>
                <div class="section-2-container-tablet">
                  <el-divider class="mt-4 mb-4"></el-divider>
                </div>
                <div class="section-2-container-tablet">
                  <p class="services-title-tablet">
                    Компания «DragAu» предоставляет комплексные услуги для
                    проведения маркетинговых кампаний, включая следующие услуги:
                  </p>
                </div>

                <el-row :gutter="20" class="section-2-container-tablet">
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Разработка,внедрение и поддержка комплексных решений для
                        проведения интегрированных маркетинговых коммуникаций с
                        потребителями
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Интеграция с существующими ИТ ресурсами заказсчика
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="section-2-container-tablet">
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Проектирование карт пути клиента (Custumer journey map)
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Проектирование матриц действий для выстраивания
                        автоматизированных ответных коммуникаций
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="section-2-container-tablet">
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Разработка мобильных приложений для сбора и обработки
                        маркетинговых данных
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Разработка и поддержка Web-порталов
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="section-2-container-tablet">
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Разработка и поддержка Telegram-ботов
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Разработка решений для осуществления СМС и E-mail
                        рассылок
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="section-2-container-tablet">
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Разработка решений для проведения переводов через
                        платежные системы
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Оказание услуг по совершению исходящих
                        звонков(Телемаркетинг)
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="section-2-container-tablet">
                  <el-col :xs="24" :sm="12">
                    <div class="">
                      <p class="server-text-description-tablet">
                        Обработка входящих коммуникаций по любому каналу:звонки
                        (Горячая линия), SMS,Telegram, Web
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-collapse-item>
          </el-collapse>

          <!-- <div class="">
                    <p class="details">свернуть <i class="el-icon-top"></i></p>
                </div> -->
          <div class="section-2-container-tablet mt-4 mb-4">
            <el-divider class=""></el-divider>
          </div>
          <div class="contact-us">
            <p class="contact-text-tablet">Если вам это интересно</p>
            <button class="btn-tablet">
              <a
                class="mailto-text"
                href="mailto:info@dragau.kz?subject=Интегрированные маркетинговые коммуникации!"
                >Напишите нам</a
              >
            </button>
            <!-- <el-button class="btn-tablet" @click="centerDialogVisible = true"
              >Напишите нам</el-button
            > -->
            <el-dialog
              title="Напишите нам"
              :visible.sync="centerDialogVisible"
              width="93.600vw"
              center
            >
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                <el-form-item class="dFlex" label="Кому:">
                  <p class="input-text-tablet">info@dragau.kz</p>
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item class="dFlex" label="Имя:" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item class="dFlex" label="От:" prop="email">
                  <el-input
                    placeholder="Введите свой email"
                    v-model="ruleForm.email"
                  ></el-input>
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item class="dFlex" label="Телефон:" prop="phone">
                  <masked-input
                    v-model="ruleForm.phone"
                    mask="\+\7 (111) 111-11-11"
                    placeholder="+7 ("
                  />
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item
                  class="dFlex-column"
                  label="Тема сообщения:"
                  prop="variants"
                >
                  <p class="input-text-tablet">
                    Интегрированные маркетинговые коммуникации
                  </p>
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item label="Сообщение:" prop="desc">
                  <el-input
                    type="textarea"
                    style="margin-top: 10px"
                    v-model="ruleForm.desc"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item>
                                <el-button type="primary" @click="onSubmit">Create</el-button>
                            </el-form-item> -->
              </el-form>
              <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="centerDialogVisible = false">Cancel</el-button> -->
                <el-button class="btn-red" @click="submitForm('ruleForm')"
                  >Отправить</el-button
                >
              </span>
            </el-dialog>
          </div>
          <div class="center">
            <el-divider direction="vertical"></el-divider>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="section-2">
        <div class="second-page-container">
          <div class="center">
            <el-divider direction="vertical"></el-divider>
          </div>
          <h1 class="main-title">
            Интегрированные <br />
            маркетинговые коммуникации
          </h1>
          <p class="main-description">
            Интегрированные маркетинговые коммуникации - это взаимодействие с
            потребителями через различные каналы и формы коммуникации, при
            котором коммуникации в каждом из используемых каналов, взаимоувязаны
            между собой и подкрепляются друг другом для достижения максимальной
            эффективности.
          </p>
          <p class="second-description">
            Подобный подход позволяет направлять различным целевым аудиториям
            точные,согласованные, маркетинговые сообщения, содействующие
            максимизации эффективности коммуникации.
          </p>
          <p class="content-text">
            Использование концепции интегрированных маркетинговых <br />
            коммуникаций предполагает решение следующих задач:
          </p>
          <el-row>
            <el-col :span="12" class="arrow">
              <el-divider class="arrow-divider"></el-divider>
              <i class="el-icon-arrow-right"></i>
            </el-col>
            <el-col :span="12">
              <div class="">
                <ul class="ul-text">
                  <li class="">
                    создание системы сообщений потребителям через различные
                    каналы и средства коммуникаций, которые координируются между
                    собой и создают целостный благориятный образ компании и её
                    продукта;
                  </li>
                  <li class="">
                    использование оптимальных комбинаций средств коммуникаций;
                  </li>
                  <li class="">
                    исключение противоречий между коммуникациями в отдельных
                    каналах с целью оказания более сильного влияния на
                    потребителя;
                  </li>
                  <li class="">
                    контроль и экономия средств за счет ведения учета затрат в
                    рамках каждой коммуникационной кампании;
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <div class="second-img">
            <img src="../assets/second-bg.png" class="code-img" alt="" />
          </div>
          <div class="">
            <p class="img-text-content">
              Компания «DragAu» предоставляет комплексный инструмент,
              позволяющий клиентам компании реализовать концепцию
              интегрированных маркетинговых коммуникаций в отношении своих
              клиентов, потребителей, партнеров и т.д.
            </p>
          </div>

          <el-collapse>
            <el-collapse-item>
              <template class="collapse-flex" slot="title">
                <div class="">
                  <p class="details">
                    подробнее <i class="el-icon-bottom"></i>
                  </p>
                </div>
              </template>
              <div>
                <p class="details-title">
                  Разработанные в компании автоматизированные комплексы
                  позволяют осуществлять интегрированные маркетинговые
                  коммуникации с использованием следующих каналов:
                </p>

                <el-row class="items-center">
                  <el-col :span="3">
                    <div class="item-container">
                      <img src="../assets/web-monitor.svg" alt="" />
                      <p class="product-items">Web-порталы</p>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="item-container">
                      <img src="../assets/telegram.svg" alt="" />
                      <p class="product-items">Telegram-боты</p>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="item-container">
                      <img src="../assets/mobile-phone.svg" alt="" />
                      <p class="product-items">Мобильные приложения</p>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="item-container">
                      <img src="../assets/sms.svg" alt="" />
                      <p class="product-items">SMS</p>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="item-container">
                      <img
                        src="../assets/payment.svg"
                        class="payment-img"
                        alt=""
                      />
                      <p class="product-items">Платежные системы</p>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="item-container">
                      <img src="../assets/mail.svg" alt="" />
                      <p class="product-items">Электронная почта</p>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="item-container">
                      <img src="../assets/phone.svg" alt="" />
                      <p class="product-items">Телефония</p>
                    </div>
                  </el-col>
                </el-row>

                <el-row class="server-margin">
                  <el-col :span="14">
                    <div class="">
                      <p class="server-text-main">
                        Автоматизированные комплексы разрабатываются
                        специалистами компании на базе промышленных
                        информационных систем, что позволяет обеспечить
                        надежность работы и безопастность данных.
                      </p>
                      <p class="server-text-description">
                        Соответствие предлагаемого решения принципам концепции
                        интегрированных маркетинговых коммуникаций
                        обеспечивается следующими функциональностями системы:
                      </p>
                      <ul class="server-ul">
                        <li class="server-text-description">
                          Информация обо всех активностях потребителя/продавца
                          во всех каналах централизовано хранится в единой
                          системе;
                        </li>
                        <li class="server-text-description">
                          Система является центром управления коммуникациями где
                          можно определить предпочтения потребителей по каналам,
                          языку и режиму коммуникации;
                        </li>
                        <li class="server-text-description">
                          Система является централизованным хранилищем шаблонов
                          для всех каналов коммуникаций;
                        </li>
                        <li class="server-text-description">
                          В системе настроены матрицы действий с прописанными
                          правилами действий при входящих коммуникациях в любом
                          канале.
                        </li>
                      </ul>
                    </div>
                  </el-col>
                  <el-col :span="10">
                    <div class="">
                      <img
                        src="../assets/server2.png"
                        class="sever-img"
                        alt=""
                      />
                    </div>
                  </el-col>
                </el-row>

                <el-divider class=""></el-divider>

                <div class="services-title-container">
                  <p class="services-title">
                    Компания «DragAu» предоставляет комплексные услуги для
                    проведения маркетинговых кампаний, включая следующие услуги:
                  </p>
                </div>

                <el-row class="server-margin">
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Разработка,внедрение и поддержка комплексных решений для
                        проведения интегрированных маркетинговых коммуникаций с
                        потребителями
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Интеграция с существующими ИТ ресурсами заказсчика
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Проектирование карт пути клиента (Custumer journey map)
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Проектирование матриц действий для выстраивания
                        автоматизированных ответных коммуникаций
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                </el-row>

                <el-row :gutter="10" class="server-margin">
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Разработка мобильных приложений для сбора и обработки
                        маркетинговых данных
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Разработка и поддержка Web-порталов
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Разработка и поддержка Telegram-ботов
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Разработка решений для осуществления СМС и E-mail
                        рассылок
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                </el-row>

                <el-row class="server-margin">
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Разработка решений для проведения переводов через
                        платежные системы
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Оказание услуг по совершению исходящих
                        звонков(Телемаркетинг)
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="">
                      <p class="server-text-description">
                        Обработка входящих коммуникаций по любому каналу:звонки
                        (Горячая линия), SMS,Telegram, Web
                      </p>
                      <el-divider class="arrow-divider-2"></el-divider>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-collapse-item>
          </el-collapse>

          <!-- <div class="">
                    <p class="details">свернуть <i class="el-icon-top"></i></p>
                </div> -->

          <el-divider class=""></el-divider>

          <div class="contact-us">
            <p class="contact-text">Если вам это интересно</p>

            <button class="btn">
              <a
                class="mailto-text"
                href="mailto:info@dragau.kz?subject=Интегрированные маркетинговые коммуникации!"
                >Напишите нам</a
              >
            </button>
            <!-- <el-button class="btn" @click="centerDialogVisible = true"
              >Напишите нам</el-button
            > -->
            <el-dialog
              title="Напишите нам"
              :visible.sync="centerDialogVisible"
              width="50%"
              center
            >
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                <el-form-item class="dFlex" label="Кому:">
                  <p class="input-text">info@dragau.kz</p>
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item class="dFlex" label="Имя:" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item class="dFlex" label="От:" prop="email">
                  <el-input
                    placeholder="Введите свой email"
                    v-model="ruleForm.email"
                  ></el-input>
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item class="dFlex" label="Телефон:" prop="phone">
                  <masked-input
                    v-model="ruleForm.phone"
                    mask="\+\7 (111) 111-11-11"
                    placeholder="+7 ("
                  />
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item
                  class="dFlex"
                  label="Тема сообщения:"
                  prop="variants"
                >
                  <p class="input-text">
                    Интегрированные маркетинговые коммуникации
                  </p>
                </el-form-item>
                <el-divider class="dividerForm"></el-divider>
                <el-form-item label="Сообщение:" prop="desc">
                  <el-input
                    type="textarea"
                    style="margin-top: 10px"
                    v-model="ruleForm.desc"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item>
                                <el-button type="primary" @click="onSubmit">Create</el-button>
                            </el-form-item> -->
              </el-form>
              <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="centerDialogVisible = false">Cancel</el-button> -->
                <el-button class="btn-red" @click="submitForm('ruleForm')"
                  >Отправить</el-button
                >
              </span>
            </el-dialog>
          </div>
          <div class="center">
            <el-divider direction="vertical"></el-divider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-tablet :hover {
  background-color: black !important;
  color: white;
}

.btn :hover {
  background-color: black !important;
  color: white;
}
.mailto-text {
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  color: white;
  text-decoration: none;
}
::v-deep .el-dialog--center .el-dialog__footer {
  text-align: center;
}

::v-deep .el-form-item__error {
  color: #f56c6c;
  font-size: 14px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 30%;
  left: 70%;
}
::v-deep input {
  background-color: black;
  border: none;
  color: white;
  outline: none;
  /* font-size: 0.790123em; */
  font-family: "Roboto", sans-serif;
  width: 100%;
}
.dFlex-column {
  display: flex;
  flex-direction: column;
}
.input-text-tablet {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0.790123em;
  font-family: "Roboto", sans-serif;
  color: white;
  font-weight: 400;
  line-height: 20px;
}
.oveflow-hidden {
  overflow-x: hidden;
}

.btn-tablet {
  background-color: black;
  color: white;
  border-radius: 50px;
  font-size: 1em;
  width: 274px;
  border-color: black;
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contact-text-tablet {
  color: white;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.services-title-tablet {
  color: white;
  font-size: 1.385em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 21.09px;
}

.server-text-description-tablet {
  color: white;
  font-size: 1.231em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 18.75px;
}

.server-text-main-tablet {
  color: white;
  font-size: 1.231em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 18.75px;
}

.elcolmarginBot-tablet {
  margin-bottom: 5.049vh;
}

.details-title-tablet {
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-bottom: 4.84vh;
  line-height: 21px;
  font-size: 1.4em;
}

.details-tablet {
  color: white;
  text-decoration: underline;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
}

.img-text-content-tablet {
  color: white;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-top: 2.225vh;
  line-height: 18.75px;
}

.second-img-tablet {
  margin-top: 3.45vh;
}

.ul-marginTop {
  margin-top: 2.217vh;
}

.ul-text-tablet {
  color: white;
  font-size: 1em;
  line-height: 18.75px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
}

.arrow-divider-tablet {
  display: block;
  height: 1px;
  /* width: 20%; */
  /* margin: 24px 0; */
}

.arrow-tablet {
  display: flex;
  align-items: center;
  color: white;
}

.content-text-tablet {
  color: white;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
  line-height: 18.75px;
}

.second-description-tablet {
  color: black;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  margin-top: 9px;
  line-height: 18.75px;
  margin-bottom: 3.325vh;
}

.main-description-tablet {
  color: black;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  margin-bottom: 0px;
  line-height: 18.75px;
}

.main-title-tablet {
  color: white;
  font-size: 1.75em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-top: 0px;
  text-align: center;
  line-height: 32px;
  margin-bottom: 2.217vh;
}

.section-2-tablet {
  background-color: #eb2629;
  width: 100vw;
  height: 100%;

  /* padding-top: 67px; */
}
.section-2-container-tablet {
  padding-right: 4.267vw;
  padding-left: 4.267vw;
}

.input-text {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  color: white;
  font-weight: 400;
}
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  display: inline-block;
  cursor: default;
  flex: 0 0 auto;
  align-self: stretch;
  /* -webkit-user-select: none; */
  opacity: 0;
  pointer-events: none;
  -webkit-user-modify: read-only;
}

.dividerForm {
  margin-top: 5px;
}

::v-deep .el-dialog--center .el-dialog__body {
  text-align: initial;
  padding-right: 4.514vw;
  padding-left: 4.514vw;
}

::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: -40px;
  right: -40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: black;
  font-size: 1.5em;
  padding: 10px;
  border-radius: 50%;
  background-color: white;
}

::v-deep .el-select .el-input .el-select__caret {
  color: red;
  font-size: 1.125em;
  transition: transform 0.3s;
  transform: rotateZ(180deg);
  cursor: pointer;
}

::v-deep .el-textarea__inner {
  min-height: 165px !important;
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  color: white;
  background-color: black;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep .el-select-dropdown__item {
  font-size: 1.125em !important;
  font-family: "Roboto", sans-serif;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}

::v-deep .el-input {
  position: relative;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  width: 100%;
}

::v-deep .el-dialog,
.el-pager li {
  background: black;
}

::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 50%;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: black;
  background-image: none;
  border: none;
  box-sizing: border-box;
  color: white;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

::v-deep .el-dialog__header {
  padding: 20px 20px 10px;
}
::v-deep .el-dialog__body {
  padding: 30px 20px;
  background-color: black;
  color: #606266;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  word-break: inherit;
}

::v-deep .el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

::v-deep .el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  margin-left: 0px !important;
  width: 100%;
}
.dFlex {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item__label {
  text-align: start;
  vertical-align: middle;
  float: left;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #eb2629;
  line-height: 20px;
  padding: 0 12px 0 0;
  margin-bottom: 0px;
  box-sizing: border-box;
}

::v-deep .el-dialog__title {
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  font-weight: 500;
  color: white;
}
.btn-red {
  background-color: #eb2629;
  color: white;
  border-radius: 50px;
  font-size: 1.125em;
  width: 274px;
  border-color: black;
  margin-bottom: 30px;
}

.center {
  text-align: center;
}

::v-deep .el-divider--vertical {
  display: inline-block;
  width: 3px;
  height: 50px;
  margin: 0 8px;
  vertical-align: middle;
  position: relative;
  background-color: white;
}

::v-deep .el-collapse-item__wrap {
  will-change: height;
  background-color: #eb2629;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: none;
}
::v-deep .el-collapse-item__arrow {
  display: none;
}

::v-deep .el-collapse {
  border-top: none;
  border-bottom: none;
}

::v-deep .el-collapse-item__header {
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  background-color: #eb2629;
  color: #303133;
  cursor: pointer;
  border-bottom: none;
  font-size: 1em;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
  text-align: center;
  justify-content: center;
}
.contact-us {
  text-align: center;
}
.contact-text {
  color: white;
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.btn {
  background-color: black;
  /* color: white; */
  border-radius: 50px;
  font-size: 1.125em;
  width: 274px;
  border-color: black;
  margin-bottom: 30px;
}
.services-title-container {
  width: 897px;
}

.services-title {
  color: white;
  font-size: 1.8462em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 28px;
}

.server-ul {
  padding-left: 25px;
}

.sever-img {
  width: 100%;
}

.server-margin {
  margin-top: 4.2vh;
}

.server-text-description {
  color: white;
  font-size: 1.385em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 21px;
}

.server-text-main {
  color: white;
  font-size: 1.385em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 21px;
}

.payment-img {
  width: 59px;
  height: 59px;
}
.item-container {
  text-align: center;
}

.items-center {
  display: flex;
  justify-content: center;
}

.product-items {
  color: white;
  font-size: 1.385em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  align-items: center;
  line-height: 21px;
}

.details-title {
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-right: 12.014vw;
  margin-left: 12.014vw;
  margin-bottom: 4.84vh;
  line-height: 28px;
  font-size: 1.8462em;
}

.details {
  color: white;
  text-decoration: underline;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
}

.img-text-content {
  color: white;
  font-size: 1.125em;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-right: 173px;
  margin-left: 173px;
  margin-top: 5.1vh;
}

.second-img {
  margin-top: 3.53vh;
}
.code-img {
  width: 100%;
}

.arrow {
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: end;
  color: white;
}
.arrow-divider {
  display: block;
  height: 1px;
  width: 20%;
  margin: 24px 0;
}
.arrow-divider-2 {
  display: block;
  height: 1px;
  width: 30%;
  margin: 24px 0;
}

.ul-text {
  color: white;
  font-size: 1.125em;
  line-height: 1.3em;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
}

.section-2 {
  background-color: #eb2629;
  width: 100vw;
  height: 100%;
  /* padding-top: 67px; */
}

.second-page-container {
  margin-left: 10.764vw;
  margin-right: 10.764vw;
}

.main-title {
  color: white;
  font-size: 2.25em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-top: 0px;
  text-align: center;
}
.main-description {
  color: black;
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  margin-left: 5.833vw;
  margin-right: 5.833vw;
  margin-bottom: 0px;
}

.second-description {
  color: black;
  font-size: 1.5em;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  margin-left: 84px;
  margin-right: 84px;
  margin-top: 9px;
}

.content-text {
  color: white;
  font-size: 1.125em;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-top: 4.5vh;
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  ::v-deep .el-form-item__error {
    color: #f56c6c;
    font-size: 14px;
    line-height: 1;
    padding-top: 4px;
    position: initial;
    /* top: 30%; */
    /* left: 70%; */
    text-align: center;
  }
  ::v-deep input {
    background-color: black;
    border: none;
    color: white;
    outline: none;
    /* font-size: 0.790123em; */
    font-family: "Roboto", sans-serif;
    width: 100%;
  }
  ::v-deep .el-divider--vertical {
    display: inline-block;
    width: 3px;
    height: 30px;
    margin: 0 8px;
    vertical-align: middle;
    position: relative;
    background-color: white;
  }
  ::v-deep .el-dialog__title {
    line-height: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 1.125em;
    font-weight: 500;
    color: white;
  }

  ::v-deep .el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    margin: 0;
  }

  ::v-deep .el-form-item__label {
    text-align: start;
    vertical-align: middle;
    float: left;
    font-size: 0.88889em;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #eb2629;
    line-height: 20px;
    padding: 0 12px 0 0;
    margin-bottom: 0px;
    box-sizing: border-box;
  }
  ::v-deep .v-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0 !important;
    background: black !important;
  }

  .el-input {
    position: relative;
    font-size: 0.790123em;
    font-family: "Roboto", sans-serif;
    display: inline-block;
    width: 100%;
  }
  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: -50px;
    right: 5px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }
}
</style>

<script>
import MaskedInput from "vue-masked-input";
export default {
  name: "SecondPage",
  data() {
    return {
      centerDialogVisible: false,
      ruleForm: {
        name: "",
        variants: "",
        email: "",
        phone: "",
        desc: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Введите свое Имя",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "Имя должно содержать от 3 до 20 символов",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Введите вашу почту",
            trigger: "blur",
          },
          {
            min: 3,
            max: 30,
            message: "Почта должна содержать от 3 до 30 символов",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Введите ваш телефон",
            trigger: "blur",
          },
          {
            min: 11,
            message: "Номер телефона введен некорректно",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("Работает?");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  components: {
    MaskedInput,
  },
};
</script>
